import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document, Block, Inline, Text } from '@contentful/rich-text-types'
import NextLink from 'next/link'
import { isExternal } from '~/lib/externalLink'
import { makeAbsolute } from '~/lib/makeAbsolute'

type NodeRenderer = (node: Block | Inline | Text, children: React.ReactNode) => React.ReactNode

interface CustomOptions extends Options {
  renderNode?: {
    [K in keyof Options['renderNode']]: NodeRenderer
  }
}

export type SeoTextHeadlineType = 'H1' | 'H2'

interface SeoTextProps {
  className?: string
  headline: string
  headlineType: SeoTextHeadlineType
  seoText: Document
}

const Headline = ({
  children,
  className,
  type,
}: {
  children: React.ReactNode
  className: string
  type: SeoTextHeadlineType
}) => {
  const HeadlineTag = type.toLowerCase() as 'h1' | 'h2'

  return <HeadlineTag className={className}>{children}</HeadlineTag>
}

const options: CustomOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block, children: React.ReactNode) => (
      <p className="text-15-22-sg font-extralight text-sg-black/75">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node: Block, children: React.ReactNode) => (
      <ul className="mt-5 list-disc pl-4 text-sg-black/75 marker:text-sg-black/75">{children}</ul>
    ),
    [INLINES.HYPERLINK]: (node: Inline, children: React.ReactNode) => {
      const { uri } = node.data
      const isExternalLink = isExternal(uri)

      if (isExternalLink) {
        return (
          <a
            href={uri}
            className="text-sg-black/75 underline decoration-sg-black/75"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )
      }

      return (
        <NextLink
          href={makeAbsolute(uri)}
          className="text-sg-black/75 underline decoration-sg-black/75"
        >
          {children}
        </NextLink>
      )
    },
  },
}

export const SeoText: React.FC<SeoTextProps> = ({ headline, headlineType, seoText, className }) => {
  return (
    <div className={`px-4 text-sg-black/75 lg:px-20 ${className}`}>
      <Headline className="mb-[20px] text-15-22-sg font-medium text-sg-black" type={headlineType}>
        {headline}
      </Headline>
      {documentToReactComponents(seoText, options)}
    </div>
  )
}
